import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import logo from "../../static/img/logo.png";
import { v4 } from "uuid";

const IngredientList = ({ ingredients, picture }) => {
  const recipeImage = (
    <Img
      className="recipe-image"
      fixed={picture ? picture.childImageSharp.fixed : logo}
    />
  );
  return (
    <div className="ingredients-container">
      <ul className="ingredients-list">
        {ingredients.map((ingredient) => {
          return <li key={v4()}>{ingredient}</li>;
        })}
      </ul>
      {recipeImage}
    </div>
  );
};

IngredientList.propTypes = {
  ingredients: PropTypes.array,
  picture: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default IngredientList;
