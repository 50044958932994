import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo/Seo";
import Content, { HTMLContent } from "../components/Content";
import IngredientList from "../components/IngredientList";

import bannerDummy from "../../static/img/banner_image_logo.png";

export const RecipeTemplate = ({
  image,
  content,
  contentComponent,
  description,
  tags,
  recipename,
  ingredients,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <>
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundPosition: `center`,
          backgroundAttachment: `fixed`,
        }}
      >
        <div
          style={{
            backgroundImage: `url(${bannerDummy})`,
            backgroundPosition: "center",
            width: "100%",
            height: "100%",
          }}
        ></div>
      </div>

      <section className="section">
        <div style={{ margin: "30px" }}>
          <Link to={`/rezepte`}> ← Zurück zu den Rezepten</Link>
        </div>
        {helmet || ""}
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1 recipe">
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                {recipename}
              </h1>
              <p>{description}</p>
              <IngredientList ingredients={ingredients} picture={image} />

              <PostContent content={content} />
              {tags && tags.length ? (
                <div style={{ marginTop: `2rem` }}>
                  <div className="tags">
                    {tags.map((tag) => (
                      <Link
                        key={tag + `tag`}
                        to={`/tags/${kebabCase(tag)}/`}
                        className="tag is-primary"
                      >
                        {tag}
                      </Link>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

RecipeTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  recipename: PropTypes.string,
  ingredients: PropTypes.array,
  helmet: PropTypes.object,
};

const Recipe = ({ data, location }) => {
  const { markdownRemark: recipe } = data;

  const image = recipe.frontmatter.image
    ? recipe.frontmatter.image.childImageSharp.resize
    : null;

  return (
    <Layout>
      <SEO
        title={recipe.frontmatter.recipename}
        description={recipe.frontmatter.description}
        image={image}
        pathname={location.pathname}
      />

      <RecipeTemplate
        content={recipe.html}
        contentComponent={HTMLContent}
        ingredients={recipe.frontmatter.ingredients.list}
        description={recipe.frontmatter.description}
        tags={recipe.frontmatter.tags}
        recipename={recipe.frontmatter.recipename}
        image={recipe.frontmatter.image}
      />
    </Layout>
  );
};

Recipe.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default Recipe;

export const pageQuery = graphql`
  query RecipeByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        recipename
        description
        tags
        ingredients {
          list
        }
        image {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
            fixed(height: 240) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`;
